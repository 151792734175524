<template>
  <div class="home">
    <div class="fondo__mobile"></div>
    <span class="d-flex justify-content-center align-items-center">
      <img src="../assets/img/mont2.png" alt="" class="mont__fondo" />
    </span>
    <div class="d-none">
      <Navegacion />
    </div>
    <div class="container">
      <div class="row justify-content-center">
        <div
          class="
            col-12 col-md-6
            d-flex
            flex-column
            justify-content-center
            align-items-center
            contenedor__general
          "
        >
          <img src="../assets/img/logo.svg" alt="Logo" class="img__logo" />
          <div
            class="row row__width justify-content-center contenedor__slide my-2"
          >
            <!-- barra -->
            <span :class="'d-flex ' + estiloBarra"></span>
            <div
              class="
                borde__botones
                d-flex
                justify justify-content-center
                align-items-center
              "
            >
              <div
                class="
                  col-6
                  d-flex
                  justify-content-center
                  align-items-center
                  borde
                "
              >
                <button
                  :class="
                    'boton__none d-flex justify-content-center align-items-center ' +
                    estiloLetra
                  "
                  @click="cambiarLoginVista(0)"
                >
                  <img
                    src="../assets/img/token__active.svg"
                    v-if="loginVista"
                  /><img
                    src="../assets/img/token__disable.svg?asd"
                    rel="preload"
                    v-else
                  />
                  <p>Acceder con token</p>
                </button>
              </div>
              <div
                class="col-6 d-flex justify-content-center align-items-center"
              >
                <button
                  :class="
                    'boton__none d-flex justify-content-center align-items-center ' +
                    estiloLetra2
                  "
                  @click="cambiarLoginVista(1)"
                >
                  <img
                    src="../assets/img/usuario__disable.svg"
                    v-if="loginVista"
                  /><img
                    src="../assets/img/usuario__active.svg?asd"
                    rel="preload"
                    v-else
                  />
                  <p>Acceder con usuario</p>
                </button>
              </div>
            </div>
          </div>
          <!-- Token -->
          <div
            class="row row__width justify-content-center contenedor__input"
            v-if="loginVista"
          >
            <div
              class="
                col-12 col-lg-8
                d-flex
                justify-content-center
                align-items-center
                my-3
              "
            >
              <p>
                Escribe tu token para acceder
              </p>
            </div>
            <div
              class="
                col-12 col-lg-8
                d-flex
                justify-content-center
                align-items-center
                contenedor
              "
            >
              <div
                class="row row__width justify-content-center align-items-center"
              >
                <img
                  src="../assets/img/candado.svg"
                  alt="candado"
                  class="d-flex candado"
                />
                <input
                  type="password"
                  class="col d-flex"
                  placeholder="Token"
                  v-model="token"
                />
              </div>
            </div>
            <div
              class="
                col-12 col-lg-8
                d-flex
                justify-content-center
                align-items-center
                mt-4
              "
            >
              <button class="btn__acceder" @click="loginToken()">
                ACCEDER
              </button>
            </div>
          </div>
          <!-- Fin token -->
          <!-- Usuario -->
          <form
            class="row row__width justify-content-center contenedor__input"
            v-else
            @submit="loginUserAndPassword()"
          >
            <div
              class="
                col-12 col-lg-8
                d-flex
                justify-content-center
                align-items-center
                my-3
              "
            >
              <p>Acceder con tus datos de usuario</p>
            </div>
            <div
              class="
                col-12 col-lg-8
                d-flex
                flex-column
                justify-content-center
                align-items-center
                contenedor
              "
            >
              <div
                class="
                  row row__width
                  justify-content-center
                  align-items-center
                  mb-4
                "
              >
                <img
                  src="../assets/img/nombre.svg"
                  alt="candado"
                  class="d-flex candado"
                />
                <input
                  type="text"
                  class="col d-flex"
                  placeholder="Usuario"
                  v-model="user"
                />
              </div>
              <div
                class="row row__width justify-content-center align-items-center"
              >
                <img
                  src="../assets/img/candado.svg"
                  alt="candado"
                  class="d-flex candado"
                />
                <input
                  type="password"
                  class="col d-flex"
                  placeholder="Contraseña"
                  v-model="password"
                />
              </div>
            </div>
            <div
              class="
                col-12 col-lg-8
                d-flex
                justify-content-center
                align-items-center
                mt-4
              "
            >
              <button class="btn__acceder" type="submit">
                ACCEDER
              </button>
            </div>
          </form>
          <!-- fin usuario -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import Navegacion from "../components/Navegacion.vue";
import axios from "axios";
import { mapState } from "vuex";
export default {
  name: "Login",
  components: {
    Navegacion,
  },
  data() {
    return {
      loginVista: true,
      mostrar: false,
      token: "",
      user: "",
      password: "",
    };
  },
  methods: {
    cambiarLoginVista(x) {
      this.loginVista = x !== 1;
    },
    cambiarConfig__section(x) {
      this.mostrar = x === 1;
    },
    cerrarConfig_section(x) {
      if (x === 1) {
        this.mostrar = false;
      }
    },
    loginToken() {
      if (this.token.length > 0 && this.token !== "") {
        let urlGet = this.apiUrl + "/login/token/" + this.token;
        axios.get(urlGet).then((res) => {
          if (res.status === 200 && res.data.auth === true) {
            sessionStorage.setItem("user", JSON.stringify(res.data.user));
            this.$router.push('/');
          }else{
            alert("Token incorrecto");
          }
        });
      }
    },
    loginUserAndPassword() {
      if (!!this.user && !!this.password) {
        //if user is email return false
        if (this.user.indexOf("@") !== -1) {
          alert("El usuario debe ser un nombre de usuario")
          return
        }
        let url = this.apiUrl + "/login/credenciales/" + this.user + "/" + this.password;
        axios.get(url).then(res => {
          if (res.status === 200 && res.data.auth === true){
            sessionStorage.setItem("user", JSON.stringify(res.data.user))
            this.$router.push('/')
          }else{
            alert("Usuario o contraseña incorrectos")
          }
        })
      }else{
        alert("Ingrese usuario y contraseña")
      }
    },
  },
  computed: {
    ...mapState(["apiUrl"]),
    estiloBarra() {
      return this.loginVista ? "left__0" : "left__100";
    },
    estiloLetra() {
      return this.loginVista ? "active" : "boton";
    },
    estiloLetra2() {
      return this.loginVista ? "boton" : "active";
    },
  },
};
</script>
<style lang="scss" scoped>
#app {
  .home {
    background-image: url("../assets/img/fondo-desk.png");
    background-size: 40% 100%;
    background-repeat: no-repeat;
    background-position-x: left;
    position: relative;
    height: 100vh;
    padding: 80px 0 320px 0;
    @media (max-width: 900px) {
      padding: 100px 0 320px 0;
      background-image: none;
    }
    .fondo__mobile {
      @media (max-width: 900px) {
        position: absolute;
        top: 0;
        width: 100%;
        height: 300px;
        background: url("../assets/img/fondo-movil.svg") no-repeat top center;
        background-size: 100%;
      }
    }
  }

  .montaña {
    position: absolute;
    background-repeat: no-repeat;
    width: 20%;
    height: 100%;
    z-index: 0;
    overflow: hidden;
  }
  .fondo__gris {
    background-color: rgba(41, 41, 41, 0.7);
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 98;
    display: flex;
    justify-content: center;
    align-items: center;
    .config {
      position: fixed;
      background-color: #fff;
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 0 0 0 10px;
      width: 40%;
      height: 100%;
      right: 0;
      top: 0;
      z-index: 99;
      @media (max-width: 1200px) {
        width: 70%;
      }
      p {
        color: #b1b1d2;
        font-weight: 500;
        font-size: 16px;
      }
      button {
        cursor: pointer;
        img {
          width: 100%;
          height: 100%;
          min-height: 40px;
        }
      }
      .active {
        color: #292929;
      }
      label {
        position: relative;
        display: inline-block;
        min-width: 50px;
        height: 25px;
        input {
          display: none;
        }
        .slide {
          position: absolute;
          cursor: pointer;
          border-radius: 15px;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: #b1b1d2;
          -webkit-transition: 0.4s;
          transition: 0.4s;
          padding: 8px;
          color: #fff;
          display: flex;
          align-items: center;
          &:before {
            position: absolute;
            content: "";
            height: 15px;
            width: 15px;
            left: 5px;
            background-color: white;
            -webkit-transition: 0.4s;
            transition: 0.6s;
            border-radius: 50%;
          }
        }
        input:checked + .slide {
          background-color: #292929;
        }
        input:checked + .slide:before {
          -webkit-transform: translateX(26px);
          -ms-transform: translateX(26px);
          transform: translateX(26px);
          left: 0;
        }
      }
      .guardar {
        background-color: #28edca;
        border-radius: 5px;
        min-height: 56px;
        width: 100%;
        color: #353535;
        font-weight: 600;
        font-size: 16px;
      }
    }
  }

  .home {
    .mont__fondo {
      position: absolute;
      bottom: 0;
      height: 30%;
      width: 30%;
      @media (max-width: 1400px) {
        width: 50%;
      }
      @media (max-width: 900px) {
        height: 15%;
      }
    }
    .container {
      z-index: 1;
      height: 590px;
      @media (max-width: 376px) {
        padding:0 !important;
      }
      p {
        margin-bottom: 0;
      }
      .row__width {
        width: 100%;
      }

      .contenedor__config {
        button {
          max-width: 40px;
        }
      }
      button {
        background-color: transparent;
        outline: none;
        border: none;
      }
      .contenedor__general {
        .img__logo {
          width: 80%;
          margin: 20px 0px;
        }
        .contenedor__slide {
          position: relative;
          min-height: 50px;
          button {
            padding: 10px 15px;
            img {
              margin-right: 5px;
              @media (max-width: 900px) {
                margin-right: 0;
              }
            }
          }
          .borde__botones {
            border-bottom: 1px solid rgba($color: #000000, $alpha: 0.05);
            button {
              @media (max-width: 1400px) {
                display: flex;
                flex-direction: column;
              }
            }
          }
          .borde {
            border-right: 1px solid rgba($color: #000000, $alpha: 0.05);
          }
          .boton {
            color: #b1b1d2;
            font-size: 18px;
            width: 100%;
            font-weight: 400;
            @media (max-width: 900px) {
              font-size: 13px;
            }
          }
          .active {
            color: #353535;
            font-size: 18px;
            font-weight: 600;
            @media (max-width: 900px) {
              font-size: 13px;
            }
          }
          span:before {
            content: "";
            position: absolute;
            background-color: #28edca;
            width: 30%;
            left: 0;
            height: 7px;
            bottom: 0;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .left__0::before {
            left: 12%;
            @media (max-width: 600px) {
              left: 11%;
            }
          }
          .left__100::before {
            left: 60%;
            @media (max-width: 900px) {
              left: 59%;
            }
          }
        }
        .contenedor__input {
          input {
            background-color: #fff;
            width: 100%;
            border: none;
            outline: none;
            font-weight: 400;
            color: rgba(53, 53, 53, 0.6);
            font-size: 16px;
            min-height: 56px;
            border-radius: 8px;
            &::placeholder {
              color: rgba(53, 53, 53, 0.6);
            }
          }
          p {
            color: #353535;
            font-size: 20px;
            font-weight: 600;
            width: 100%;
            text-align: left;
          }
          button {
            background-color: #28edca;
            min-height: 56px;
            border-radius: 5px;
            color: #353535;
            font-size: 18px;
            font-weight: 600;
            display: flex;
            justify-content: center;
            align-items: center;
            min-width: 50px;
            &:focus,
            &:hover {
              background-color: #22c9aa;
            }
          }
          .btn__acceder {
            width: 100%;
            z-index: 99;
          }
          .contenedor {
            position: relative;
            input {
              padding-left: 45px;
            }
            .candado {
              max-width: 40px;
              height: 50px;
              position: absolute;
              left: 15px;
            }
          }
        }
      }
    }
  }
}
</style>

