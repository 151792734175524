<template>
  <div id="app">
    <transition leave-active-class="animate__animated animate__fadeOut">
      <section class="d-flex justify-content-center align-items-center" v-if="loading">
        <div id="overlayer">
            <span class="loader">
              <span class="loader-inner"></span>
            </span>
        </div>
      </section>
    </transition>
    <router-view/>
  </div>
</template>
<script>
export default {
  name: 'App',
  data () {
    return {
      loading: true
    }
  },
  mounted () {
    window.addEventListener("load", () => {
      setTimeout(()=> this.loading=false,500)
    })
  }
}
</script>
<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
#app {
  font-family: 'Poppins', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  text-align: center;
  color: #2c3e50;
  background-color: #F5F5F5 !important;


  #overlayer {
    width:100%;
    height:100%;
    position:fixed;
    z-index:999999;
    background:#fff;
    display: flex;
    justify-content: center;
    align-items: center;
    top:0;
    left:0;
  }
  .loader {
    width: 50px;
    height: 50px;
    position: relative;
    z-index:3;
    border: 4px solid #28EDCA;
    border-radius: 8px;
    animation: loader 2s infinite ease;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .loader-inner {
    vertical-align: top;
    width: 35px;
    max-height: 35px;
    background-color: #28EDCA;
    animation: loader-inner 2s infinite ease-in;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @keyframes loader {
    0% {
      transform: rotate(0deg);
    }

    25% {
      transform: rotate(180deg);
    }

    50% {
      transform: rotate(180deg);
    }

    75% {
      transform: rotate(360deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes loader-inner {
    0% {
      height: 0%;
    }

    25% {
      height: 0%;
    }

    50% {
      height: 100%;
    }

    75% {
      height: 100%;
    }

    100% {
      height: 0%;
    }
  }
}

</style>
