<template>
  <div
    class="col-12 d-flex flex-column justify-content-center align-items-center"
  >
    <div class="row row__width justify-content-center row__detalle">
      <span

          class="d-flex justify-content-center align-items-center"
        :class="{
          'mt-0': item.percent == 100,
          active: item.percent == item.finalized_percent,
          disable: item.percent !== item.finalized_percent,
        }"
        style="cursor: pointer"
      >
        <p style="user-select: none;" @click="toggleDetails()">{{ itemPercentRounded }}%</p>

        <div
          v-if="showDetails"
          class="
            detalles
            d-flex
            flex-column
            justify-content-center
            align-items-center
          "
        >
          <div class="col-12 d-flex justify-content-start align-items-center">
            <span>
              <h1>{{ item.titulo_categoria }}</h1>
              <p v-if="item.informacion.length>0">{{ item.informacion }}</p>
            </span>
          </div>
          <button class="close-btn" @click="toggleDetails()">
            <img src="../assets/img/cerrar.svg" alt="" />
          </button>
        </div>

        <img
          v-if="item.percent == item.finalized_percent"
          :src="require(`@/assets/img/${getGenero}`)"
          alt=""
          :class="getGeneroClass"
        />
      </span>
    </div>
    <div
      v-if="!item.active && item.percent !== item.finalized_percent"
      class="row row__width justify-content-center"
    >
      <span
        class="punto__disable d-flex justify-content-center align-items-center"
        ><p></p></span>
    </div>
    <div v-if="item.active && item.percent !== item.finalized_percent">
      <span
        class="punto__active d-flex justify-content-center align-items-center"
      >
        <p></p>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: ["item","getGenero","getGeneroClass"],
  name: "tramite-item",
  data() {
    return {
      showDetails: true,
    };
  },
  methods: {
    toggleDetails() {
      this.showDetails = !this.showDetails;
    }
  },
  computed: {
    itemPercentRounded() {
      return Math.round(this.item.percent);
    }
  }
};
</script>

<style>
</style>