import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'

Vue.use(VueRouter)

const isAuthenticated = () => {
  try {
    const user = JSON.parse(sessionStorage.getItem('user'));
    return user && user.login_token;
  } catch (e) {
    console.error(e);
    return false;
  }
}

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    beforeEnter: (to, from, next) => {
      if (to.name !== 'Login' && !isAuthenticated()) next({name: 'Login'})
      else next()
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  }
]

const router = new VueRouter({
  routes
})

export default router
