<template>
  <div class="body__background">
    <div id="overlayer" v-if="loading">
      <span class="loader">
        <span class="loader-inner"></span>
      </span>
    </div>
    <span class="fondo__gris" v-show="mostrar">
      <span class="config container justify-content-center">
        <div class="row justify-content-center">
          <div
            class="col-10 d-flex justify-content-start align-items-center mt-4"
          >
            <button class="ms-auto cerrar__btn" @click="toggleConfig()">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="20"
                height="20"
                viewBox="0 0 50 50"
                style="fill: #000000"
              >
                <path
                  d="M 9.15625 6.3125 L 6.3125 9.15625 L 22.15625 25 L 6.21875 40.96875 L 9.03125 43.78125 L 25 27.84375 L 40.9375 43.78125 L 43.78125 40.9375 L 27.84375 25 L 43.6875 9.15625 L 40.84375 6.3125 L 25 22.15625 Z"
                ></path>
              </svg>
            </button>
          </div>
          <div
            class="
              col-10
              d-flex
              justify-content-between
              align-items-center
              mt-4
            "
          >
            <p class="">Notificaciones push</p>
            <label class="switchBtn">
              <input type="checkbox" v-model="config.push" />
              <div class="slide round"></div>
            </label>
          </div>
          <div
            class="
              col-10
              d-flex
              justify-content-between
              align-items-center
              mt-4
            "
          >
            <p>Email</p>
            <label class="switchBtn">
              <input type="checkbox" v-model="config.email" />
              <div class="slide round"></div>
            </label>
          </div>
          <div
            class="col-10 d-flex justify-content-center align-items-center mt-4"
          >
            <button class="guardar" @click="setConfig()">Guardar</button>
          </div>
          <div
            class="col-10 d-flex justify-content-center align-items-center mt-4"
          >
            <button
              @click="signOut()"
              class="btn text-danger w-100 p-2 border border-danger"
            >
              Cerrar sesión
            </button>
          </div>
        </div>
      </span>
    </span>
    <span class="background__mont"></span>
    <div class="container justify-content-center align-items-center">
      <div class="row justify-content-center">
        <div
          class="
            col-12
            d-flex
            justify-content-center
            align-items-center
            contenedor__top
            mb-5
          "
        >
          <div class="row row__width justify-content-center">
            <div class="col-6 d-flex justify-content-start align-items-center">
              <a href=""><img src="../assets/img/logo__blanco.svg" alt="" /></a>
            </div>
            <div class="col-6 d-flex justify-content-end align-items-center">
              <!--<Navegacion/>-->
              <p class="d-none d-lg-flex wsp mb-0 me-4">
                ¿Tienes alguna duda? <br />
                Contáctanos por WhatsApp
              </p>
              <a href="https://api.whatsapp.com/send?phone=376355899&text=%C2%A1Hola%21+Necesito+que+me+ayud%C3%A9is+%C2%BFpod%C3%A9is+echarme+una+mano%3F+😊" target="_blank" class="me-2"
                ><img src="../assets/img/wps__blanco.svg" alt=""
              /></a>
              <button class="" @click="toggleConfig()">
                <img src="../assets/img/config__btn.svg" alt="" />
              </button>
            </div>
          </div>
        </div>
        <div
          class="
            col-12
            d-flex
            justify-content-center
            align-items-center
            contenedor__top
          "
        >
          <div class="row row__width justify-content-center">
            <div
              class="
                col-12
                d-flex
                justify-content-center
                align-items-center
                contenedor__mid
              "
            >
              <div class="row row__width justify-content-center">
                <div
                  class="
                    col-12
                    d-flex
                    justify-content-center
                    align-items-center
                  "
                >
                  <span
                    class="
                      btn__usuario
                      d-flex
                      justify-content-center
                      align-items-center
                    "
                  >
                    <img
                      width="75"
                      height="75"
                      src="../assets/img/check.svg"
                      alt=""
                    />
                  </span>
                </div>
                <div
                  class="
                    col-12
                    d-flex
                    flex-column
                    justify-content-center
                    align-items-center
                    mt-5
                    span__porcentajes
                  "
                >
                  <tramite-item
                    v-for="item of mapTramites"
                    :key="item.id"
                    :item="item"
                    :getGenero="getGenero"
                    :getGeneroClass="getGeneroClass"
                  ></tramite-item>

                  <div class="w-100 d-flex justify-content-center">
                    <button
                      class="
                        disable
                        d-flex
                        justify-content-center
                        align-items-center
                      "
                    >
                      <p><img src="../assets/img/ojo.svg" alt="" /></p>
                    </button>
                  </div>
                  <div class="w-100 d-flex justify-content-center">
                    <button
                      class="
                        disable
                        cerrar__btn
                        d-flex
                        justify-content-center
                        align-items-center
                      "
                    >
                      <p>
                        <img src="../assets/img/cerrar__btn.svg" alt="" />
                      </p>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Navegacion from "../components/Navegacion.vue";
import TramiteItem from "../components/TramiteItem.vue";
import { mapState } from "vuex";
import { Plugins } from '@capacitor/core';
const { PushNotifications } = Plugins;
export default {
  name: "Home",
  components: {
    Navegacion,
    TramiteItem,
  },
  data() {
    return {
      mostrar: false,
      detalle: false,
      detalle3: false,
      config: {
        email: true,
        push: true,
      },
      tramites: [],
      loading: true,
    };
  },
  methods: {
    toggleConfig() {
      this.mostrar = !this.mostrar;
    },
    toggleDetailInfo(item) {
      item["showDetail"] = !item["showDetail"];
    },
    detalle__1(x) {
      if (x === 1) {
        this.detalle = true;
      }
    },
    detalle__3(x) {
      this.detalle3 = x === 1;
    },
    cerrar__detalle(x) {
      if (x === 1) {
        this.detalle = false;
      }
    },
    cerrar__detalle__3(x) {
      if (x === 1) {
        this.detalle3 = false;
      }
    },
    getUser() {
      return JSON.parse(sessionStorage.getItem("user"));
    },
    async getTramites() {
      const user = this.getUser();
      const url =
        this.apiUrl + "/usuario/tramites/" + user.id + "/" + user.login_token;
      const res = await axios.get(url);
      return res.data?.tramites || [];
    },
    async getConfig() {
      const user = this.getUser();
      const url =
        this.apiUrl +
        "/usuario/configuracion/" +
        user.id +
        "/" +
        user.login_token;
      const res = await axios.get(url);
      const { notificacion_email, notificacion_push } = res.data;
      const email = notificacion_email[0].notificacion_email;
      const push = notificacion_push[0].notificacion_push;
      return {
        email: email == "1",
        push: push == "1",
      };
    },
    async setConfig() {
      this.loading = true;
      const user = this.getUser();
      const url =
        this.apiUrl +
        "/usuario/configuracion/" +
        user.id +
        "/" +
        (this.config.email ? "1" : "0") +
        "/" +
        (this.config.push ? "1" : "0") +
        "/" +
        user.login_token;
      const res = await axios.put(url);
      if (res.data.resultado == "ok") {
        console.log("Configuracion actualizada");
        this.mostrar = false;
        this.loading = false;
      } else {
        console.error("Error en configuracion");
        this.loading = false;
      }
    },
    async signOut() {
      this.loading = true;
      sessionStorage.removeItem("user");
      this.$router.push("/login");
      this.loading = false;
    },
  },
  computed: {
    ...mapState(["apiUrl"]),
    mapTramites() {
      const total = this.tramites.length;
      const totalFinalized = (
        this.tramites.filter((e) => e.id_estado_tramite == 3) || []
      ).length;
      return this.tramites
        .map((item, index) => {
          return {
            ...item,
            percent: ((index + 1) / total) * 100,
            active: item.id_estado_tramite == 1,
            finalized_percent: (totalFinalized / total) * 100,
          };
        })
        .reverse();
    },
    getGenero() {
      const user = this.getUser();
      return user.sexo === "masculino" ? "hombre.svg" : "mujer.svg";
    },
    getGeneroClass() {
      const user = this.getUser();
      return user.sexo === "masculino" ? "hombre" : "mujer";
    },
  },
  mounted() {
    Promise.all([this.getConfig(), this.getTramites()])
      .then(([config, tramites]) => {
        this.config.email = config.email;
        this.config.push = config.push;
        this.tramites = tramites;
        this.loading = false;

        // Request permission to use push notifications
        // iOS will prompt user and return if they granted permission or not
        // Android will just grant without prompting
        PushNotifications.requestPermissions().then((result) => {
         PushNotifications.register();
        });

        // On success, we should be able to receive notifications
        PushNotifications.addListener("registration", (token) => {
          console.log("Push registration success, token: " + token.value);
        });

        // Some issue with our setup and push will not work
        PushNotifications.addListener("registrationError", (error) => {
          console.log("Error on registration: " + JSON.stringify(error));
        });

        // Show us the notification payload if the app is open on our device
        PushNotifications.addListener(
          "pushNotificationReceived",
          (notification) => {
            console.log("Push received: " + JSON.stringify(notification));
          }
        );

        // Method called when tapping on a notification
        PushNotifications.addListener(
          "pushNotificationActionPerformed",
          (notification) => {
            console.log("Push action performed: " + JSON.stringify(notification));
          }
        );
      })
      .catch((e) => {
        console.error(e);
        this.loading = false;
      });
  },
};
</script>

<style lang="scss">
#app {
  background-image: none !important;
  padding: 0 !important;
  .body__background {
    background-color: #fff !important;
    background-image: url("../assets/img/fondo__login.svg");
    background-size: 100%;
    background-repeat: no-repeat;
    background-position-y: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    @media (max-width: 900px) {
      background-image: url("../assets/img/mobile__fondo__login.svg");
    }
    .row__width {
      width: 100%;
    }
    .row__detalle {
      span {
        position: relative;
      }
      .active{
        .detalles{
          left: 120% !important;
          word-break: break-all;
          min-width: 300px;
          max-width:300px;
          @media (max-width: 992px) {
            left: unset !important;
          }
        }
      }

    }
    button {
      background-color: transparent;
      border: none;
      outline: none;
    }
    .background__mont {
      background-image: url("../assets/img/mont.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      width: 100%;
      height: 100%;
      top: 2%;
      position: absolute;
      @media (max-width: 1200px) {
        background-size: 200% 100%;
        background-position-x: 49%;
        top: 4%;
      }
      @media (max-width: 1000px) {
        background-size: 300% 100%;
        background-position-x: 49%;
        top: 4%;
      }
      @media (max-width: 500px) {
        background-size: 480% 100%;
        background-position-x: 49%;
        top: 4%;
      }
    }
    .fondo__gris {
      background-color: rgba(41, 41, 41, 0.7);
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 98;
      display: flex;
      justify-content: center;
      align-items: center;
      .config {
        position: fixed;
        background-color: #fff;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 0px 0px 0px 10px;
        width: 35%;
        height: 100%;
        right: 0;
        top: 0;
        z-index: 100;
        @media (max-width: 960px) {
          width: 75%;
        }
        p {
          color: #b1b1d2;
          font-weight: 500;
          font-size: 16px;
        }
        button {
          min-height: 52px;
          cursor: pointer;
          img {
            width: 100%;
            height: 100%;
            min-height: 40px;
          }
        }
        .active {
          color: #292929;
        }
        label {
          position: relative;
          display: inline-block;
          min-width: 50px;
          height: 25px;
          input {
            display: none;
          }
          .slide {
            position: absolute;
            cursor: pointer;
            border-radius: 15px;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #b1b1d2;
            -webkit-transition: 0.4s;
            transition: 0.4s;
            padding: 8px;
            color: #fff;
            display: flex;
            align-items: center;
            &:before {
              position: absolute;
              content: "";
              height: 15px;
              width: 15px;
              left: 5px;
              background-color: white;
              -webkit-transition: 0.4s;
              transition: 0.6s;
              border-radius: 50%;
            }
          }
          input:checked + .slide {
            background-color: #28edca;
          }
          input:checked + .slide:before {
            -webkit-transform: translateX(26px);
            -ms-transform: translateX(26px);
            transform: translateX(26px);
            left: 0;
          }
        }
        .guardar {
          background-color: #28edca;
          border-radius: 5px;
          //min-height: 56px;
          width: 100%;
          color: #353535;
          font-weight: 600;
          font-size: 16px;
        }
      }
    }
    .container {
      padding-top: 0;
      .contenedor__top {
        position: relative;
        margin-top: 20px;
        .wsp {
          color: #fff;
          font-size: 16px;
          font-weight: 500;
          text-align: left;
          padding: 0px 15px;
          position: relative;
          &::after {
            content: "";
            position: absolute;
            background-color: #fff;
            width: 7%;
            height: 2px;
            right: -7px;
            bottom: 50%;
          }
        }
        .medio {
          margin: 0px 5px 0px 15px;
          padding-top: 30px;
        }
        .config__btn {
          padding-top: 30px;
        }
      }
      .contenedor__mid {
        background-position-x: center;
        background-position-y: top;
        p {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 0;
        }
        .btn__usuario {
          border-radius: 50%;
          min-height: 100px;
          min-width: 100px;
          background-color: transparent;
          border: 4px solid #28edca;
          position: relative;
          button {
            position: absolute;
            background-color: #ffffff;
            box-shadow: 0px 3px 6px #00000029;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #28edca;
            font-size: 22px;
            font-weight: 600;
            width: 25px;
            height: 25px;
            bottom: -10px;
          }
        }
        .span__porcentajes {
          position: relative;
          &:before {
            content: "";
            position: absolute;
            top: 0;
            background-color: rgba(207, 207, 223, 0.3);
            height: 100%;
            width: 5px;
          }
          .row__detalle {
            position: relative;
            .detalles {
              position: absolute;
              //right: 0;
              //width: 300px;
              //top: 0;
              left: 55%;
              background: white;
              padding: 10px;
              box-shadow: 0 0 8px 0.5px #33333330;
              border-radius: 8px;
              h1 {
                color: #cfcece;
                font-weight: 600;
                font-size: 20px;
                margin-bottom: 0;
                @media (max-width: 992px) {
                  font-size: 16px;
                }
              }
              p {
                color: #bab7b7;
                background-color: transparent;
                text-align: left;
                font-size: 16px;
                height: 30px;
                width: 100%;
                font-weight: 500;
                padding: 0;
                display:inline;

              }
              a {
                text-decoration: none;
                img {
                  margin-right: 5px;
                }
                p {
                  color: #28edca;
                  background-color: transparent;
                  text-align: left;
                  font-size: 16px;
                  height: 30px;
                  width: 100%;
                  font-weight: 600;
                  padding: 0;
                  box-shadow: none;
                }
              }
              .close-btn {
                display: none;
                position: absolute;
                right: 4px;
                top: 4px;
                z-index:5;
              }

              @media (max-width: 992px) {
                z-index: 4;
                background-color: #fff;
                box-shadow: 0px 3px 6px #00000029;
                border-radius: 8px;
                padding: 10px 35px 10px 10px;
                //max-width: 300px;
                min-width: 300px;
                max-width: 100%;
                word-break: break-all;

                a {
                  p {
                    color: #b1b1d2;
                  }
                }
                top: calc(100% + 8px);
                left: unset;
                .close-btn {
                  display: block;
                }
              }
            }
            .detalle {
              position: absolute;
              z-index: 4;
              width: 500px;
              right: 5%;
              top: 0;
              h2 {
                color: #353535;
                font-weight: 600;
                font-size: 20px;
                text-align: left;
              }
              p {
                color: rgba(41, 41, 41, 0.7);
                width: 100%;
                background-color: transparent;
                text-align: left;
                font-size: 16px;
                height: 30px;
                width: 100%;
                font-weight: 500;
                padding: 0;
                box-shadow: none;
                justify-content: start !important;
              }
              span {
                width: 100%;
                position: relative;
                input {
                  background-color: #ffffff;
                  box-shadow: 0px 3px 6px #00000029;
                  border-radius: 59px;
                  padding-left: 15px;
                  height: 40px;
                  border: none;
                  outline: none;
                  width: 100%;
                  color: rgba(53, 53, 53, 0.5);
                  font-weight: 500;
                  font-size: 16px;
                  &::placeholder {
                    color: rgba(53, 53, 53, 0.5);
                  }
                }
                button {
                  position: absolute;
                  right: 15px;
                }
              }
              @media (max-width: 1200px) {
                background-color: #fff;
                box-shadow: 0px 3px 6px #00000029;
                border-radius: 8px;
                padding: 15px;
                max-width: 250px;
                z-index: 4;
                top: 68%;
                left: 37%;
                min-height: 180px;
                p {
                  color: #292929;
                  font-size: 14px;
                }
                span {
                  input {
                    font-size: 12px;
                    color: rgba(53, 53, 53, 0.5);
                    min-height: 50px;
                    box-shadow: 0px 3px 6px #00000029;
                    border-radius: 10px;
                    padding-left: 5px;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    &::placeholder {
                      font-size: 12px;
                    }
                  }
                  button {
                    bottom: 0;
                  }
                }
              }
              @media (max-width: 400px) {
                z-index: 4;
                top: 68%;
                left: 10%;
              }
            }
          }
          .active {
            min-height: 90px;
            max-width: 90px;
            border: 2px solid #28edca;
            border-radius: 50%;
            padding: 0;
            z-index: 1;
            margin: 50px 0 200px 0;
            .hombre {
              position: absolute;
              top: 60%;
              height: 250px;
              right: -92px;
            }
            .mujer {
              position: absolute;
              top: 60%;
              height: 250px;
              right: 20px;
            }
            p {
              color: #fff;
              font-size: 22px;
              font-weight: 600;
              background-color: #28edca;
              border-radius: 50%;
              height: 70px;
              width: 70px;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
          .disable {
            margin: 50px 0;
            z-index: 4;
            p {
              color: #e8e8eb;
              font-size: 20px;
              font-weight: 600;
              background-color: #cfcfdf;
              border-radius: 50%;
              height: 64px;
              width: 64px;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
          .punto__active {
            background-color: #cfcfdf;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            position: relative;
            padding: 0;
            margin: 50px 0;
            p {
              background-color: #28edca;
              border-radius: 50%;
              width: 20px;
              height: 20px;
              padding: 0;
              box-shadow: 0px 3px 6px #00000029;
            }
          }
          .punto__mensaje {
            margin: 12px 0 0 0;
            border: 2px solid #28edca;
            border-radius: 50%;
            width: 55px;
            height: 55px;
            border-radius: 50%;
            position: relative;
            padding: 0;
            .hombre {
              position: absolute;
              left: -215%;
              top: 60px;
              height: 250px;
            }
            &::before {
              content: "";
              width: 5px;
              background-color: #28edca;
              min-height: 170px;
              position: absolute;
              top: -15px;
            }
            p {
              background-color: #28edca;
              border-radius: 50%;
              width: 45px;
              height: 45px;
              padding: 0;
              z-index: 2;
            }
          }
          .mensajes__debajo {
            margin-top: 220px;
          }
          .punto__disable {
            margin: 50px 0;
            background-color: #cfcfdf;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            padding: 0;
            z-index: 1;
            p {
              background-color: #fff;
              border-radius: 50%;
              width: 20px;
              height: 20px;
              padding: 0;
              box-shadow: 0px 3px 6px #00000029;
            }
          }
          .cerrar__btn {
            margin-top: 0;
            margin-bottom: 0;
            background-color: rgba(103, 191, 180, 0.3);
            border-radius: 50%;
            height: 70px;
            width: 70px;
            border-radius: 50%;
            z-index: 1;
            p {
              height: 50px;
              width: 50px;
              background-color: #28edca;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              img {
                height: 80%;
              }
            }
          }
        }
      }
    }
  }
}
</style>