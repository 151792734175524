import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loginToken: '',
    userInfo: {},
    apiUrl: 'https://app.vivirandorra.com/',
    //apiUrl: 'https://localhost:8000',
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
